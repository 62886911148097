import { isDEVMODE, globalStorage, domStorage, viewStorage } from '../_globals'
import gsap from 'gsap'
import { Observer } from 'gsap/Observer'
gsap.registerPlugin(Observer)
import SolutionsSubmenu from './solutions-submenu'
import ContactAJAX from './contact-ajax'

export default class Header {
  constructor() {
    this.DOM = { el: domStorage.header }

    this.DOM.brand = this.DOM.el.querySelector('.brand')
    this.DOM.toggleBtn = this.DOM.el.querySelector('.Nav__toggle')
    this.DOM.menuContainer = this.DOM.el.querySelector('.Nav__container')
    this.DOM.menuItems = this.DOM.menuContainer.querySelectorAll('.Nav__item')
    this.DOM.menuLines = this.DOM.menuContainer.querySelectorAll('.line')
    this.DOM.menuButtons = this.DOM.menuContainer.querySelectorAll('.Nav__buttons li')
    this.DOM.solutionsSubmenu = this.DOM.menuContainer.querySelector('.SolutionsSubmenu')

    // For mobile, by default the menu is not open
    globalStorage.menuOpen = false
    // MatchMedia variable
    this.mm = gsap.matchMedia()
    // Breakpoint
    this.breakpoint = 992
    // Responsive conditions
    this.responsiveObject = {
      isDesktop: `(min-width: ${this.breakpoint}px)`,
      isMobile: `(max-width: ${this.breakpoint - 1}px)`
    }

    this.addEvents()
  }

  addEvents() {
    const { body, overlay } = domStorage
    const { el, toggleBtn, menuContainer, menuItems, menuLines, menuButtons, solutionsSubmenu } = this.DOM

    // Contact AJAX
    this.contact = new ContactAJAX(el)

    // Header with Scroll events
    this.onScrollObserver = Observer.create({
      target: window,
      type: 'scroll',
      tolerance: 0,
      onUp: () => !el.classList.contains('--visible') && el.classList.add('--visible'),
      onDown: () => el.classList.contains('--visible') && el.classList.remove('--visible')
    })

    this.scrolledObserver = Observer.create({
      target: window,
      type: 'scroll',
      onChange: (self) => {
        if (window.scrollY > 50 && !body.classList.contains('--scrolled')) body.classList.add('--scrolled')
        if (window.scrollY <= 50 && body.classList.contains('--scrolled')) body.classList.remove('--scrolled')
      }
    })

    // Solutions Submenu
    this.solutionsSubmenu = new SolutionsSubmenu(menuContainer)

    // Open/Close menu events
    this.mm.add(this.responsiveObject, context => {
      let { isMobile } = context.conditions


      // Add the event to the context
      context.add('toggle', this.toggle.bind(this))

      // Adding events depending of viewport sizes
      // + Adapting the styles
      if (isMobile) {
        // Each time we resize the window, we close the menu
        this.close()

        // Cleaning styles
        gsap.set([menuItems, menuButtons], { opacity: 0, y: 20 })
        gsap.set(menuLines, { scaleX: 0 })

        // Adding the event
        toggleBtn.addEventListener('click', context.toggle)
      } else {
        // Each time we resize the window, we close the menu
        this.close()

        // Cleaning styles
        gsap.set(menuContainer, { clearProps: 'all', overwrite: true })
        gsap.set(menuLines, { clearProps: 'scaleX' })
        gsap.set(menuItems, { clearProps: 'all' })
        gsap.set(menuButtons, { clearProps: 'all' })
        gsap.set(solutionsSubmenu, { clearProps: 'xPercent' })
      }

      return () => {
        // Cleaning the event
        toggleBtn.removeEventListener('click', context.toggle)
      }
    })

    globalStorage.openMobileMenu = () => this.open()
    globalStorage.closeMobileMenu = () => this.close()

    // overlay
    overlay.addEventListener('click', () => globalStorage.menuOpen && this.close())
  }

  toggle(e) {
    if (e) e.preventDefault()

    globalStorage.menuOpen = !globalStorage.menuOpen

    globalStorage.menuOpen ? this.open() : this.close()
  }

  open(e) {
    const { viewScroll } = viewStorage
    const { menuContainer, menuItems, menuLines, menuButtons } = this.DOM

    if (e) e.preventDefault()

    globalStorage.menuOpen = true

    gsap
      .timeline({
        defaults: { ease: 'power3.inOut', duration: 1 },
        onStart: () => {
          domStorage.body.classList.add('--show-menu')
          viewScroll.scroll.stop()
        },
        onComplete: () => {
          // Cleaning styles
          gsap.set(menuItems, { clearProps: 'all' })
          gsap.set(menuLines, { clearProps: 'all' })
          gsap.set(menuButtons, { clearProps: 'all' })
        }
      })
      .to(menuContainer, { clipPath: 'inset(0% 0% 0% 0%)', duration: 0.7, overwrite: true }, 0.5)
      .fromTo(menuItems, { opacity: 0, y: 20 }, { opacity: 1, y: 0, stagger: 0.05, ease: 'power3', duration: 0.8, overwrite: true }, 0.95)
      .fromTo(menuLines, { scaleX: 0 }, { scaleX: 1, transformOrigin: '0% 0%', stagger: 0.05, overwrite: true }, 0.7)
      .fromTo(menuButtons, { opacity: 0, y: 20 }, { opacity: 1, y: 0, ease: 'power3', duration: 0.8, overwrite: true }, 1.5)
  }

  close(e) {
    const { viewScroll } = viewStorage
    const { menuContainer } = this.DOM

    e && e.preventDefault()

    gsap
      .timeline({
        defaults: { ease: 'power3.inOut', duration: 0.7 },
        onComplete: () => {
          globalStorage.menuOpen = false
          domStorage.body.classList.remove('--show-menu')
          domStorage.body.classList.remove('--show-submenu')
          // if (viewScroll.scroll) viewScroll.scroll.start()

          if (viewScroll) viewScroll.scroll.start()

          this.solutionsSubmenu.closeSubmenuMobile()
        }
      })
      .to(menuContainer, { clipPath: 'inset(0% 0% 0% 100%)', overwrite: true }, 0)
  }
}
