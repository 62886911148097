import { viewStorage } from '../_globals'

export default class ShowMore {
  constructor(container) {
    this.DOM = { container }
    this.DOM.content = this.DOM.container.querySelector('.content')
    
    if (!this.DOM.content) return

    this.DOM.wswygContent = this.DOM.content.querySelector('.wswyg--content')
    this.DOM.readMoreButton = this.DOM.content.querySelector('.read-more')

    this.settings = {
      hasReadMore: '--has-read-more',
      showMore: '--show-more'
    }

    this.setShowMore()
  }

  setShowMore() {
    const { content, wswygContent, readMoreButton } = this.DOM
    const { hasReadMore } = this.settings

    if (wswygContent.offsetHeight >= 450) {
      // The wswyg content has overflow and truncated
      // show read more / read less button
      content.classList.add(hasReadMore)
      readMoreButton.addEventListener('click', this.toggleReadMore.bind(this))
    } else {
      content.classList.remove(hasReadMore)
    }
  }

  toggleReadMore(e) {
    e.preventDefault()

    const { content } = this.DOM
    const { showMore } = this.settings

    content.classList.toggle(showMore)
  }

  destroy() {
    const { readMoreButton } = this.DOM

    readMoreButton.removeEventListener('click', this.toggleReadMore.bind(this))
  }
}
