import { domStorage, globalStorage, viewStorage } from '../_globals'
import gsap from 'gsap'
import { SplitText } from 'gsap/SplitText'
gsap.registerPlugin(SplitText)

export const pageIntro = (delay) => {
  const { targetLocation } = globalStorage.taxi
  let hasHash = null
  if (targetLocation) targetLocation.hasHash && (hasHash = targetLocation.hasHash)

  // For anchors
  if (window.location.hash || hasHash) {
    const anchorEl = document.querySelector(window.location.hash)
    if (anchorEl) gsap.to(window, { scrollTo: anchorEl, duration: 1, ease: 'power3.inOut' }, 3)

    return
  } else {
    const { current } = viewStorage
    const currentDataset = current.dataset.taxiView
    const { header } = domStorage
  
    const allSections = [...current.children]
    const allSectionsExceptTheFirst = allSections.slice(1)

    const pageHero = current.querySelector('.PageHero')
    let pageHeroBtn, pageHeroVideoC, pageHeroVideo, pageHeroVisualC, pageHeroVisual
    if (pageHero) pageHeroBtn = pageHero.querySelector('.Btn')
    if (pageHero) pageHeroVideoC = pageHero.querySelector('.video__container')
    if (pageHeroVideoC) pageHeroVideo = pageHeroVideoC.querySelector('video')
    if (pageHero) pageHeroVisualC = pageHero.querySelector('.visual__container')
    if (pageHeroVisualC) pageHeroVisual = pageHeroVisualC.querySelector('.visual')

    const pageHeader = current.querySelector('.PageHeader')
    let pageHeaderWrapper, pageHeaderBtn, pageHeaderVisualC, pageHeaderVisual
    if (pageHeader) pageHeaderWrapper = pageHeader.querySelector('.wrapper')
    if (pageHeader) pageHeaderBtn = pageHeader.querySelector('.Btn')
    if (pageHeader) pageHeaderVisualC = pageHeader.querySelector('.visual__container')
    if (pageHeaderVisualC) pageHeaderVisual = pageHeaderVisualC.querySelector('.visual')

    // Single Solution
    const pageHeaderSolution = current.querySelector('.PageHeaderSolution')
    let pageHeaderSolutionVisualC, pageHeaderSolutionVisual, pageHeaderSolutionType, pageHeaderSolutionLinks
    if (pageHeaderSolution) pageHeaderSolutionVisualC = pageHeaderSolution.querySelector('.visual__container.--clipping')
    if (pageHeaderSolutionVisualC) pageHeaderSolutionVisual = pageHeaderSolutionVisualC.querySelector('.visual')
    if (pageHeaderSolution) pageHeaderSolutionType = pageHeaderSolution.querySelector('.type')
    if (pageHeaderSolution) pageHeaderSolutionLinks = pageHeaderSolution.querySelectorAll('a')

    // Page Contact
    const pageHeaderContact = current.querySelector('.PageHeaderContact')
    let pageHeaderContactInfos
    if (pageHeaderContact) pageHeaderContactInfos = pageHeaderContact.querySelector('.infos')

    // Page POPP
    const pageHeaderPopp = current.querySelector('.PageHeaderPopp')
    let pageHeaderPoppVisual, pageHeaderPoppContent, pageHeaderStars
    if (pageHeaderPopp) pageHeaderPoppVisual = pageHeaderPopp.querySelector('.visual__container')
    if (pageHeaderPopp) pageHeaderPoppContent = pageHeaderPopp.querySelector('.wrapper')
    if (pageHeaderPopp) pageHeaderStars = pageHeaderPopp.querySelector('.icon')

    // Archive Blog
    const pageHeaderBlog = current.querySelector('.PageHeaderBlog')
    let pageHeaderBlogForm
    if (pageHeaderBlog) pageHeaderBlogForm = pageHeaderBlog.querySelector('.Form')

    // Single Blog
    const article = current.querySelector('.Article')
    let articleHead, articleCategories, articleContent
    if (article) articleHead = article.querySelector('.wrapper > .head')
    if (article) articleCategories = article.querySelector('.wrapper > .categories')
    if (article) articleContent = article.querySelector('.wrapper > .wswyg--content')


    // Page 404
    const page404 = current.className === 'NotFound'
    let page404Text 
    if (page404) page404Text = current.querySelector('p')

    // Title
    const pageTitle = current.querySelector('h1')
    const pageTitleSplitted = new SplitText(pageTitle, { type: 'lines, chars' })
    const pageTitleSplittedParent = new SplitText(pageTitle, { type: 'lines', linesClass: '--oh' })

    const tl = gsap
      .timeline({
        delay: delay ? delay : 0.5,
        defaults: { ease: 'power3', duration: 1 },
        onStart: () => {
          setTimeout(() => { window.scrollTo(0, 0) }, 100)
        },
        onComplete: () => {
          // Cleaning styles
          // if (pageHeroVisualC) gsap.set(pageHeroVisualC, { clearProps: 'all' })
          // if (pageHeroVisual) gsap.set(pageHeroVisual, { clearProps: 'all' })

          // if (pageHeroBtn) gsap.set(pageHeroBtn, { clearProps: 'all' })
          // if (pageHeaderBtn) gsap.set(pageHeaderBtn, { clearProps: 'all' })
          if (allSectionsExceptTheFirst && allSectionsExceptTheFirst.length) gsap.set(allSectionsExceptTheFirst, { clearProps: 'y,opacity' })

          if (pageHeaderWrapper) gsap.set(pageHeaderWrapper, { clearProps: 'all' })

          // Single Solution
          if (pageHeaderSolutionVisualC) gsap.set(pageHeaderSolutionVisualC, { clearProps: 'all' })
          if (pageHeaderSolutionType) gsap.set(pageHeaderSolutionType, { clearProps: 'opacity'})
          if (pageHeaderSolutionLinks) gsap.set(pageHeaderSolutionLinks, { clearProps: 'opacity' })

          // Page Contact
          if (pageHeaderContactInfos) gsap.set(pageHeaderContactInfos, { clearProps: 'all' })

          // Page POPP
          if (currentDataset === 'pagePopp') {
            if (pageHeaderPoppVisual) gsap.set(pageHeaderPoppVisual, { clearProps: 'opacity' })
            if (pageHeaderPoppVisual) gsap.set(pageHeaderPoppVisual.children[0], { clearProps: 'scale' })
            if (pageHeaderPoppContent.children[0].children[0].children.length) gsap.set([...pageHeaderPoppContent.children[0].children[0].children].slice(1), { clearProps: 'all' })
          }

          if (currentDataset === 'archiveBlog') {
            if (pageHeaderBlogForm) gsap.set(pageHeaderBlogForm, { clearProps: 'all' })
          }

          if (currentDataset === 'singleArticle') {
            if (articleHead) gsap.set(articleHead, { clearProps: 'all' })
            if (articleCategories) gsap.set(articleCategories, { clearProps: 'all' })
            if (articleContent) gsap.set(articleContent, { clearProps: 'all' })
          }

          // if (current.className === 'Page') gsap.set(current.querySelector('.wswyg--content'), {  clearProps: 'all' })
        }
      })
  
      tl.fromTo(pageTitleSplitted.chars, { yPercent: 100 }, { yPercent: 0, stagger: 0.005, duration: 0.5 }, currentDataset === 'singleSolution' ? 1 : 0.5)
    
      // Page Hero
      if (pageHeroVideoC) tl.fromTo(pageHeroVideoC, { opacity: 0 }, { opacity: 1, ease: 'power3.inOut' }, 0)
      if (pageHeroVisualC) tl.fromTo(pageHeroVisualC, { opacity: 0 }, { opacity: 1, ease: 'power3.inOut' }, 0)
      if (pageHeroVideo) tl.fromTo(pageHeroVideo, { scale: 1.2 }, { scale: 1, ease: 'power3.inOut' }, 0)
      if (pageHeroVisual) tl.fromTo(pageHeroVisual, { scale: 1.2 }, { scale: 1, ease: 'power3.inOut' }, 0)
      if (pageHeroBtn) tl.fromTo(pageHeroBtn, { opacity: 0, y: 10 }, { opacity: 1, y: 0 }, 1.25)
    
      // PageHeader
      if (pageHeaderBtn) tl.fromTo(pageHeaderBtn, { opacity: 0, y: 10 }, { opacity: 1, y: 0 }, 1.25)
      if (pageHeaderWrapper) tl.from(pageHeaderWrapper, { height: '100vh', ease: 'power3.inOut' }, 0.7)
      // if (pageHeaderVisualC) tl.fromTo(pageHeaderVisualC, { opacity: 0 }, { opacity: 1, ease: 'power3.inOut' }, 1.2)
      // if (pageHeaderVisual) tl.fromTo(pageHeaderVisual, { scale: 1.2 }, { scale: 1, ease: 'power3.inOut' }, 1.2)

      if (currentDataset === 'singleSolution') {
        if (pageHeaderSolutionVisualC) tl.fromTo(pageHeaderSolutionVisualC, { opacity: 0 }, { opacity: 1, ease: 'power3.inOut' }, 0)
        if (pageHeaderSolutionVisual) tl.fromTo(pageHeaderSolutionVisual, { scale: 1.2 }, { scale: 1, ease: 'power3.inOut' }, 0)
        if (pageHeaderSolutionType) tl.fromTo(pageHeaderSolutionType, { opacity: 0 }, { opacity: 1, ease: 'power3.inOut' }, 0.35)
        if (pageHeaderSolutionLinks) tl.fromTo(pageHeaderSolutionLinks, { opacity: 0 }, { opacity: 1, ease: 'power3.inOut' }, 1)
      }

      if (currentDataset !== 'pageAbout' && current.className !== 'Page' && allSectionsExceptTheFirst.length) tl.fromTo(allSectionsExceptTheFirst, { y: 20, opacity: 0 }, { y: 0, opacity: 1 }, '-=0.6')
  
      if (current.className === 'Page') tl.fromTo(current.querySelector('.wswyg--content'), { y: 20, opacity: 0 }, { y: 0, opacity: 1 })

      if (currentDataset === 'pageContact') {
        if (pageHeaderContactInfos) tl.fromTo(pageHeaderContactInfos, { opacity: 0 }, { opacity: 1 }, '-=1')
      }

      if (currentDataset === 'pagePopp') {
        if (pageHeaderPoppVisual) tl.fromTo(pageHeaderPoppVisual, { opacity: 0 }, { opacity: 1, ease: 'power3.inOut' }, 0)
        if (pageHeaderPoppVisual) tl.fromTo(pageHeaderPoppVisual.children[0], { scale: 1.2 }, { scale: 1, ease: 'power3.inOut' }, 0)
        if (pageHeaderPoppContent.children[0].children[0].children.length) tl.fromTo([...pageHeaderPoppContent.children[0].children[0].children].slice(1), { y: 20, opacity: 0 }, { y: 0, opacity: 1, ease: 'power3.inOut' }, 0)
        if (pageHeaderStars) tl.fromTo(pageHeaderStars, { opacity: 0 }, { opacity: 1, ease: 'power3.inOut' }, 0.1)
      }

      if (currentDataset === 'archiveBlog') {
        if (pageHeaderBlogForm) tl.fromTo(pageHeaderBlogForm, { opacity: 0, y: 20 }, { opacity: 1, y: 0, ease: 'power3.inOut' }, 0)
      }

      if (currentDataset === 'singleArticle') {
        if (articleHead) tl.fromTo(articleHead, { opacity: 0, y: 20 }, { opacity: 1, y: 0 }, 0.3)
        if (articleCategories) tl.fromTo(articleCategories, { opacity: 0, y: 20 }, { opacity: 1, y: 0 }, 0.4)
        if (articleContent) tl.fromTo(articleContent, { opacity: 0, y: 20 }, { opacity: 1, y: 0 }, 0.5)
      }

      if (page404Text) tl.fromTo(page404Text, { opacity: 0 }, { opacity: 1 }, '-=1')
    
    return tl
  }
}