import { isDEVMODE } from '../_globals'
import gsap from 'gsap'
import { Observer } from 'gsap/Observer'
gsap.registerPlugin(Observer)

export default class IconsMarquee {
  constructor(container) {
    this.DOM = { container }
    this.DOM.marquee = this.DOM.container.querySelector('.IconsMarquee')

    if (!this.DOM.marquee) return

    this.setMarquee()
  }

  setMarquee() {
    const { marquee } = this.DOM

    this.object = {
      value: 1
    }

    this.tl = gsap
      .timeline({ 
        repeat: -1, 
        onReverseComplete: () => this.tl.progress(1) 
      })
      .fromTo(marquee, { xPercent: 0 }, { xPercent: -111.1, duration: 8, ease: 'none' })
    
    // Observer.create({
    //   id: 'Observer',
    //   target: window,
    //   type: 'wheel,scroll,touch',
    //   onChangeY: (self) => {
    //     let v = self.velocityY * 0.001
    //     v = gsap.utils.clamp(-60, 60, v)
    //     this.tl.timeScale(v)

    //     // To alternate the direction while scrolling up or down
    //     let resting = 1
    //     v < 0 ? resting = -1 : resting = 1

    //     gsap.fromTo(this.object, { value: v }, { value: resting, duration: 1, onUpdate: () => this.tl.timeScale(this.object.value) })
    //   }
    // })

  }

  destroy() {
    const { tl } = this

    if (tl) {
      tl.kill()
      Observer.getById('Observer').kill()
    }
  }
}
