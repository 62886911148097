import { isDEVMODE, viewStorage } from '../_globals'
import Swiper, { Navigation } from 'swiper'
Swiper.use([Navigation])

export default class Testimonial {
  constructor(container) {
    this.DOM = { container }
    this.DOM.swiper = this.DOM.container.querySelector('.swiper')

    if (!this.DOM.swiper) return

    this.setSwiper()
  }

  setSwiper() {
    const { container, swiper } = this.DOM

    this.testimonialSwiper = new Swiper(swiper, {
      slidesPerView: 'auto',
      spaceBetween: 30,
      navigation: {
        prevEl: container.querySelector('.prev'),
        nextEl: container.querySelector('.next')
      }
    })
  }

  destroy() {
    const { testimonialSwiper } = this

    if (testimonialSwiper) testimonialSwiper.destroy()
  }
}
