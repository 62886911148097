import Form from './form'
import TiltCards from '../animations/tilt-cards'

export default class Contact {
  constructor(container) {
    this.DOM = { container }
    this.DOM.contactForm = this.DOM.container.querySelector('.ContactForm .Form')

    if (!this.DOM.contactForm) return

    this.form = new Form(this.DOM.contactForm, { output: 'popup' })
    this.form.reCaptcha = true;
    this.tiltCards = new TiltCards(this.DOM.contactForm)
  }

  destroy() {
    const { form, tiltCards } = this

    if (form) form.destroy()
    if (tiltCards) tiltCards.destroy()
  }
}
