import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

export default class VisionClipPath {
  constructor(container) {
    this.DOM = { container }
    this.DOM.circles = this.DOM.container.querySelector('.circles')

    if (!this.DOM.circles) return

    this.DOM.circleLeft = this.DOM.circles.querySelector('.circle.--left')
    this.DOM.circleRight = this.DOM.circles.querySelector('.circle.--right')
    this.DOM.circleLeftTitle = this.DOM.circleLeft.querySelector('span:first-child')
    this.DOM.intersectionTitle = this.DOM.circleLeft.querySelector('span:nth-child(2)')
    this.DOM.circleRightTitle = this.DOM.circleRight.querySelector('span')

    // MatchMedia variable
    this.mm = gsap.matchMedia()
    // Breakpoint
    this.breakpoint = 768

    this.setScrollAnimation()
  }

  setScrollAnimation() {
    const { container, circleLeft, circleRight, circleLeftTitle, circleRightTitle, intersectionTitle } = this.DOM

    gsap.set([ circleLeftTitle, circleRightTitle, intersectionTitle ], { yPercent: -50 })
    gsap.set([circleLeftTitle, circleRightTitle], { xPercent: 0 })

    this.tl = gsap
      .timeline({
        scrollTrigger: {
          trigger: container,
          start: 'top top',
          end: 'bottom bottom',
          scrub: true
        }
      })

    this.mm.add({
      isDesktop: `(min-width: ${this.breakpoint}px)`,
      isMobile: `(max-width: ${this.breakpoint - 1}px)`
    }, (context) => {
      const { isDesktop, isMobile } = context.conditions

      if (isDesktop) {
        this.tl 
          // Circle right
          .to(circleRight, { scale: 1 }, 0)
          // Circle left
          .fromTo(circleLeft, { xPercent: -80, yPercent: 80, opacity: 0 }, { xPercent: 0, yPercent: 0, opacity: 1 }, 0.4)
          // Titles
          .fromTo([ circleLeftTitle, intersectionTitle, circleRightTitle ], { opacity: 0, y: 20 }, { opacity: 1, y: 0, stagger: 0.07 }, 1)
      } else {
        gsap.set(circleRight, { xPercent: -50 })
        gsap.set(circleRightTitle, { xPercent: -50 })
        gsap.set(circleLeftTitle, { xPercent: 20 })
        gsap.set(intersectionTitle, { xPercent: -50, yPercent: 100 })

        this.tl
          // Circle top
          .to(circleRight, { scale: 1, yPercent: -20 }, 0)
          // Circle bottom
          .fromTo(circleLeft, { yPercent: 120, opacity: 0 }, { yPercent: 50, opacity: 1 }, 0.4)
          // Titles
          .fromTo([ circleLeftTitle, intersectionTitle, circleRightTitle ], { opacity: 0, y: 20 }, { opacity: 1, y: 0, stagger: 0.07 }, 1)        
      }
    })
  }

  destroy() {
    const { tl } = this

    if (tl) tl.kill()
  }
}
