import { Renderer } from '@unseenco/taxi';
import { isDEVMODE, viewStorage } from '../_globals'
import ShowMore from '../modules/show-more'
import WknPlyr from '../modules/wkn-plyr'
import ArticlesAJAX from '../modules/articles-ajax'
import RelatedSolutions from '../modules/related-solutions'

export default class SingleSolution extends Renderer {
  initialLoad() {
    this.onEnter()
  }

  onEnter() {
    this.DOM = {
      presentation: viewStorage.current.querySelector('.SolutionPresentation'),
      video: viewStorage.current.querySelector('.SolutionVideo'),
      relatedArticles: viewStorage.current.querySelector('.SolutionRelatedArticles'),
      relatedSolutionsSection: viewStorage.current.querySelector('.RelatedSolutions'),
    }

    this.init()
  }

  init() {
    if (isDEVMODE) console.log(`Init Page : ${this.content.dataset.taxiView}`)
    if (isDEVMODE) console.log(viewStorage.current)

    const { presentation, video, relatedArticles, relatedSolutionsSection } = this.DOM

    if (presentation) this.showMore = new ShowMore(presentation)
    if (video) this.plyr = new WknPlyr(video)
    if (relatedArticles) this.articles = new ArticlesAJAX(relatedArticles)
    if (relatedSolutionsSection) this.relatedSolutions = new RelatedSolutions(relatedSolutionsSection)
  }
 
  onLeaveCompleted() {
    const { showMore, plyr, articles, relatedSolutions } = this

    if (showMore) showMore.destroy()
    if (plyr) plyr.destroy()
    if (articles) articles.destroy()
    if (relatedSolutions) relatedSolutions.destroy()
  }
}
