import { isDEVMODE, viewStorage } from '../_globals'

export default class Accordions {
  constructor(container) {
    this.DOM = { container }
    this.DOM.accordions = this.DOM.container.querySelectorAll('.Accordion')

    if (!this.DOM.accordions.length) return

    this.settings = {
      activeClassName: '--open'
    }

    this.init()
  }

  init() {
    const { accordions } = this.DOM

    accordions.forEach(accordion => {
      const button = accordion.querySelector('button')
      button.addEventListener('click', this.toggle.bind(this))
    })
  }

  toggle(e) {
    e.preventDefault()

    const current = e.currentTarget.parentNode
    const content = current.querySelector('.Accordion__body')

    current.classList.toggle(this.settings.activeClassName)

    if (content.style.maxHeight) {
      content.style.maxHeight = null
    } else {
      content.style.maxHeight = `${content.scrollHeight}px`;
    }
  }

  destroy() {
    const { accordions } = this.DOM

    accordions.forEach(accordion => {
      const button = accordion.querySelector('button')
      button.removeEventListener('click', this.toggle.bind(this))
    })
  }
}
