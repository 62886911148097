import { isDEVMODE, globalStorage, viewStorage, domStorage } from '../_globals'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { pageIntro } from './page-intro'

export const GlobalIntro = () => {

  const { intro, body } = domStorage
  const { hasSmoothScroll, viewScroll } = viewStorage;

  // if (isDEVMODE) console.log('Intro')

  const timeline = gsap.timeline({
    paused: true,
    defaults: {
      duration: 1,
      ease: 'power4.out'
    },
    onStart: () => {
      body.classList.remove('--preloading')
      body.classList.remove('--loading')

      // Stop Scroll
      // if (hasSmoothScroll) viewScroll.scroll.stop()
    },
    onComplete: () => {
      ScrollTrigger.refresh()
      body.classList.remove('--animating')
      body.classList.remove('--intro')

      // Remove Loader
      body.removeChild(intro)

      // Start Scroll
      // if (hasSmoothScroll) viewScroll.scroll.start()

      globalStorage.firstLoad = false

    },
    delay: 0.2
  })
  .add(pageIntro, 0)
  .fromTo(domStorage.header, { opacity: 0 }, { opacity: 1 }, '+=2')
  .play()

}
