import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { SplitText } from 'gsap/SplitText'
gsap.registerPlugin(ScrollTrigger, SplitText)

export default class ScrollTitle {
  constructor(container) {
    this.DOM = { container }
    this.DOM.scrollText = this.DOM.container.querySelector('.ScrollTitle')

    if (!this.DOM.scrollText) return

    this.settings = { duration: 1, ease: 'expo.out' }

    this.setScrollText()
  }
  
  setScrollText() {
    const { scrollText } = this.DOM

    // 1. Splitting for animating each word
    this.words = new SplitText(scrollText, { type: 'words' })

    // 2. Set all word opacity 0.3
    gsap.set(this.words.words, { opacity: 0.3 })

    // 3. Pin and animate
    this.tl = gsap
      .timeline({
        defaults: { ease: 'expo.inOut', duration: 1.2 },
        scrollTrigger: {
          trigger: scrollText.parentNode,
          start: 'top top',
          end: 'bottom bottom',
          scrub: true,
          // markers: true,
        }
      })
      .to(this.words.words, { opacity: 1, stagger: 0.2 })
  }

  destroy() {
    if (this.tl) this.tl.kill()
  }
}
