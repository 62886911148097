import { Renderer } from '@unseenco/taxi';
import { isDEVMODE, viewStorage } from '../_globals'
import Contact from '../modules/contact'

export default class PageContactt extends Renderer {
  initialLoad() {
    this.onEnter()
  }

  onEnter() {
    this.init()
  }

  init() {
    if (isDEVMODE) console.log(`Init Page : ${this.content.dataset.taxiView}`)
    if (isDEVMODE) console.log(viewStorage.current)

    // Module where you can find : Form + Tilt Cards
    // Module is already called in the contact-ajax.js file
    this.contact = new Contact(viewStorage.current)
  }

  onLeaveCompleted() {
    const { contact } = this

    if (contact) contact.destroy()
  }
}
