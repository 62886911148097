import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

export default class ActSelf {
  constructor(container) {
    this.DOM = { container }
    this.DOM.keywords = this.DOM.container.querySelectorAll('.Keyword')

    if (!this.DOM.keywords.length) return

    this.DOM.head = this.DOM.container.querySelector('.head')

    this.settings = {
      className: '--active'
    }

    this.setKeywords()
  }

  setKeywords() {
    const { head, keywords } = this.DOM
    const { className } = this.settings

    this.tl = gsap
      .timeline({
        defaults: { ease: 'expo.inOut' },
        scrollTrigger: {
          trigger: head,
          start: 'top top',
          end: '70% bottom',
          scrub: true,
          // markers: true
        }
      })
      .fromTo(keywords, {
        opacity: 0
      }, {
        opacity: 1,
        stagger: {
          each: 0.5,
          onStart() {
            const currentTarget = this.targets()[0]
            currentTarget.classList.remove(className)
          },
          onComplete() {
            const currentTarget = this.targets()[0]
            currentTarget.classList.add(className)
          }
        },
        duration: 2
      }, 0)
  }

  destroy() {
    const { tl } = this

    if (tl) tl.kill()
  }
}