
import { isDEVMODE, globalStorage, domStorage, viewStorage } from '../_globals'
import gsap from 'gsap'
import { Observer } from 'gsap/Observer'
gsap.registerPlugin(Observer)

export default class SolutionsSubmenu {
  constructor(container) {
    this.DOM = { container }

    this.DOM.solutionItem = this.DOM.container.querySelector('.Nav__item[data-slug=nos-solutions]')
    this.DOM.solutionItemButton = this.DOM.solutionItem.querySelector('.submenu-btn.--btn')

    this.DOM.solutionsSubmenu = this.DOM.container.querySelector('.SolutionsSubmenu')
    this.DOM.solutionsSubmenuBack = this.DOM.container.querySelector('#CloseSubmenu')
    this.DOM.solutionsSubmenuHeadTitle = this.DOM.solutionsSubmenu.querySelector('.head .title')
    this.DOM.solutionsSubmenuHeadParagraph = this.DOM.solutionsSubmenu.querySelector('.head p')
    this.DOM.solutionsSubmenuHeadOffer = this.DOM.solutionsSubmenu.querySelector('.OfferMenu')
    this.DOM.solutionsSubmenuSolutionsItems = this.DOM.solutionsSubmenu.querySelectorAll('li')

    // MatchMedia variable
    this.mm = gsap.matchMedia()
    // Breakpoint
    this.breakpoint = 992
    // Responsive conditions
    this.responsiveObject = {
      isDesktop: `(min-width: ${this.breakpoint}px)`,
      isMobile: `(max-width: ${this.breakpoint - 1}px)`
    }

    this.setResponsiveEvents()
  }

  setResponsiveEvents() {
    const { solutionsSubmenu, solutionItem, solutionItemButton, solutionsSubmenuBack } = this.DOM

    this.mm.add(this.responsiveObject, context => {
      let { isMobile } = context.conditions

      // Adding events to the context
      context.add('onClickIn', this.openSubmenuMobile.bind(this))
      context.add('onClickOut', this.closeSubmenuMobile.bind(this))
      context.add('onMouseEnter', this.openSubmenuDesktop.bind(this))
      context.add('onMouseLeave', this.closeSubmenuDesktop.bind(this))

      // Adding events depending of viewport sizes
      // + Adapting the styles
      if (isMobile) {
        gsap.set(solutionsSubmenu, { clearProps: 'all' })
        gsap.set(solutionsSubmenu, { clipPath: 'inset(0% 0% 0% 100%)', pointerEvents: 'none' })

        solutionItemButton.addEventListener('click', context.onClickIn)
        solutionsSubmenuBack.addEventListener('click', context.onClickOut)
      } else {
        gsap.set(solutionsSubmenu, { clearProps: 'all' })
        gsap.set(solutionsSubmenu, { opacity: 0, y: 20, pointerEvents: 'none' })

        solutionItem.addEventListener('mouseenter', context.onMouseEnter)
        solutionItem.addEventListener('mouseleave', context.onMouseLeave)
      }
      
      // Cleaning events
      return () => {
        solutionItemButton.removeEventListener('click', context.onClick)
        solutionItem.removeEventListener('mouseenter', context.onMouseEnter)
        solutionItem.removeEventListener('mouseleave', context.onMouseLeave)
      }
    })
  }

  openSubmenuMobile(e) {
    const { solutionsSubmenu, solutionsSubmenuHeadTitle, solutionsSubmenuHeadParagraph, solutionsSubmenuHeadOffer, solutionsSubmenuSolutionsItems } = this.DOM

    e.preventDefault()

    gsap
      .timeline({
        defaults: { ease: 'power3.out', duration: 1 },
        onStart: () => {
          domStorage.body.classList.add('--show-submenu')
          gsap.set(solutionsSubmenu, { pointerEvents: 'all' })
          solutionsSubmenu.scrollTo(0, 0)
        }
      })
      .fromTo(solutionsSubmenu, { clipPath: 'inset(0% 0% 0% 100%)' }, { clipPath: 'inset(0% 0% 0% 0%)', duration: 0.4, ease: 'power3.inOut', duration: 0.7 }, 0)
      .fromTo([solutionsSubmenuHeadTitle, solutionsSubmenuHeadParagraph, solutionsSubmenuHeadOffer], { opacity: 0, y: 10 }, { opacity: 1, y: 0, stagger: 0.05 }, 0.35)
      .fromTo(solutionsSubmenuSolutionsItems, { opacity: 0 }, { opacity: 1, stagger: 0.05 }, 0.6)

  }

  closeSubmenuMobile(e) {
    const { solutionsSubmenu } = this.DOM
    
    e && e.preventDefault()

    gsap
      .timeline({
        defaults: { ease: 'power3.inOut', duration: 0.7 },
        onComplete: () => {
          domStorage.body.classList.remove('--show-submenu')
          gsap.set(solutionsSubmenu, { pointerEvents: 'none' })
        }
      })
      .to(solutionsSubmenu, { clipPath: 'inset(0% 0% 0% 100%)', overwrite: true }, 0)
  }

  openSubmenuDesktop(e) {
    const { solutionsSubmenu, solutionsSubmenuHeadTitle, solutionsSubmenuHeadParagraph, solutionsSubmenuHeadOffer, solutionsSubmenuSolutionsItems } = this.DOM

    e.preventDefault()

    gsap
      .timeline({
        defaults: { ease: 'power3.out', duration: 1 },
        onStart: () => gsap.set(solutionsSubmenu, { pointerEvents: 'all' })
      })
      .fromTo(solutionsSubmenu, { opacity: 0, y: 20, clipPath: 'inset(0% 0% 0% 0%)' }, { clipPath: 'inset(0% 0% 0% 0%)', opacity: 1, y: 0, duration: 0.4, ease: 'power3.inOut', duration: 0.7, overwrite: true }, 0)
      .fromTo([solutionsSubmenuHeadTitle, solutionsSubmenuHeadParagraph, solutionsSubmenuHeadOffer], { opacity: 0, y: 10 }, { opacity: 1, y: 0, stagger: 0.05, overwrite: true }, 0.35)
      .fromTo(solutionsSubmenuSolutionsItems, { opacity: 0 }, { opacity: 1, stagger: 0.05, overwrite: true }, 0.6)

  }

  closeSubmenuDesktop(e) {
    const { solutionsSubmenu } = this.DOM

    e.preventDefault()

    gsap
      .timeline({
        defaults: { ease: 'power3.inOut', duration: 0.5 },
        onStart: () => gsap.set(solutionsSubmenu, { pointerEvents: 'none' })
      })
      .to(solutionsSubmenu, { opacity: 0, y: 20, duration: 0.4, overwrite: true }, 0)
  }
}