import { Renderer } from '@unseenco/taxi';
import { isDEVMODE, viewStorage } from '../_globals'
import IconsMarquee from '../modules/icons-marquee'

export default class PageOurSolutions extends Renderer {
  initialLoad() {
    this.onEnter()
  }

  onEnter() {
    this.DOM = {
      presentation: viewStorage.current.querySelector('.OurSolutionsPresentation')
    }

    this.init()

  }

  init() {
    if (isDEVMODE) console.log(`Init Page : ${this.content.dataset.taxiView}`)
    if (isDEVMODE) console.log(viewStorage.current)

    const { presentation } = this.DOM

    if (presentation) this.setMarquee() 
  }

  setMarquee() {
    const {  presentation } = this.DOM

    // this.marquee = new IconsMarquee(presentation)
  }

  onLeaveCompleted() {
    const { marquee } = this

    if (marquee) marquee.destroy()
  }
}
