import { Renderer } from '@unseenco/taxi';
import { isDEVMODE, viewStorage } from '../_globals'

export default class PagePopp extends Renderer {
  initialLoad() {
    this.onEnter()
  }

  onEnter() {
    this.DOM = {}

    this.init()
  }

  init() {
    if (isDEVMODE) console.log(`Init Page : ${this.content.dataset.taxiView}`)
    if (isDEVMODE) console.log(viewStorage.current)
  }

  onLeaveCompleted() {}
}
