import { isDEVMODE, domStorage, viewStorage } from '../_globals'

export default class Form {
  constructor(formSelector, params) {
    // DOM
    this.DOM = { form: formSelector }
    this.DOM.inputs = this.DOM.form.querySelectorAll('input, textarea')
    this.DOM.fields = this.DOM.form.querySelectorAll('input, select, textarea')
    this.DOM.formOutput = this.DOM.form.querySelector('.Form__output')
    this.DOM.resultText = this.DOM.formOutput.querySelector('p')
    this.DOM.submitBtn = this.DOM.form.querySelector('button[type=submit]')
    this.DOM.checkboxContainers = this.DOM.form.querySelectorAll('.CheckboxContainer')

    // Params
    this.params = params

    // Settings
    this.formURL = this.DOM.form.dataset.ajaxurl
    this.reCaptcha = false;
    this.token = '';

    this.addEvents()
  }

  addEvents() {
    const { 
      form = null, 
      inputs = null, 
      checkboxContainers = null
    } = this.DOM

    this.onSubmit = this.onSubmit.bind(this)
    this.onFocus = this.onFocus.bind(this)
    this.showMoreLabel = this.showMoreLabel.bind(this)

    form && form.addEventListener('submit', this.onSubmit)
    inputs.forEach(input => input.addEventListener('focusin', this.onFocus))
    inputs.forEach(input => input.addEventListener('focusout', this.onFocus))

    if (checkboxContainers && checkboxContainers.length) {
      checkboxContainers.forEach(container => {
        const button = container.querySelector('button')
        button.addEventListener('click', this.showMoreLabel)
      })
    }
  }

  outputPopup(text) {
    setTimeout(() => {
      this.DOM.formOutput.classList.add('--active') 

      // Clear output (the delay corresponds to the animation duration in CSS)
      setTimeout(() => {
        this.DOM.formOutput.classList.remove('--active')
        text.innerHTML = ''
      }, 7500)
    }, 250)
  }

  showMoreLabel(e) {
    e.preventDefault()

    const parent = e.currentTarget.parentNode

    parent.classList.toggle('--show-all-label')
  }

  onFocus(e) {
    const { type, currentTarget } = e
    if (type === 'focusin') currentTarget.parentNode.classList.add('is--focused')
    else currentTarget.parentNode.classList.remove('is--focused')

    if (type === 'focusout' && currentTarget.value !== '') currentTarget.parentNode.classList.add('is--filled')
    else currentTarget.parentNode.classList.remove('is--filled')
  }

  onSubmit(e) {
    let that = this;
    e.preventDefault()
    if (this.reCaptcha) {
      grecaptcha.enterprise.execute('6LeoAQ4pAAAAAHTXVJlCGz-X2K1wvgB4qE75ajG-', {action: 'login'}).then(function (token) {
        that.token = token;
        that.handleSubmit(e);
      });
    } else
      this.handleSubmit(e);
  }

  handleSubmit(e) {
    e.preventDefault()

    const { body } = domStorage
    const { viewScroll } = viewStorage
    const { fields, submitBtn ,form } = this.DOM

    let checkboxGroupRequired = form.querySelectorAll('.checkbox-group-required');
    let checkboxGroupRequiredChecked = 1;
    checkboxGroupRequired.forEach(function (element) {
      let boxChecked = element.querySelectorAll('input:checked').length;
      checkboxGroupRequiredChecked = checkboxGroupRequiredChecked * boxChecked;
      if (boxChecked == 0) {
       let errorMessage = document.querySelector('.' + element.dataset.errorMessage);
        errorMessage.style.display = 'block';
      }
    });
    if (!checkboxGroupRequiredChecked) {
      return false;
    }

    body.classList.add('--loading')

    submitBtn.disabled = true

    /* Remove previous errors */
    this.resetErrors()

    /* Add values to formData */
    const formData = this.getData()

    if (this.token != '')
      formData.append('token', this.token);

    const xhr = new XMLHttpRequest()
    xhr.open('POST', this.formURL, true)
    xhr.onreadystatechange = () => {
      if (xhr.readyState === XMLHttpRequest.DONE) {

        switch (xhr.status) {
          case 200:
            this.onSuccess(xhr.responseText)
            break
          case 422:
            this.onInvalid(xhr.responseText)
            break
          default:
            this.onServerError()
        }

        submitBtn.disabled = false

        fields.forEach(field => {
          field.disabled = false
        })

        body.classList.remove('--loading')
        // if (viewScroll) viewScroll.scroll.update()
      }
    }

    xhr.send(formData)
  }

  resetErrors() {
    const { form, resultText } = this.DOM

    resultText.innerHTML = ''
    resultText.classList.remove('is--error')

    const errors = form.querySelectorAll('span.is--error')
    errors.forEach((err) => {
      err.parentNode.classList.remove('is--error')
      err.parentNode.removeChild(err)
    })

  }

  getData() {
    const { fields } = this.DOM

    const formData = new FormData()
    fields.forEach(field => {
      field.disabled = true
      if (field.type === 'radio') {
        if (field.checked) formData.append(field.name, field.value)
      } else if (field.type === 'file') {
        if (field.multiple) {
          const ins = field.files.length;
          for (let i = 0; i < ins; i++) {
            formData.append(field.name[i], field.files[i]);
          }
        } else {
          formData.append(field.name, field.files[0]);
        }
      } else if (field.type === 'checkbox') {
        if (field.name === 'rgpd') {
          formData.append(field.name, field.checked)
        } else {
          if (field.checked) formData.append(field.name, field.value)
        }
      }
        else {
          formData.append(field.name, field.value)
        }
    })

    return formData
  }

  onSuccess(text) {
    const { form, resultText } = this.DOM
    
    resultText.classList.contains('is--error') && resultText.classList.remove('is--error')

    resultText.textContent = text

    // For output fixed popup
    if (this.params.output === 'popup') this.outputPopup(resultText)

    form.reset()
  }

  onInvalid(text) {
    const { form } = this.DOM
    const json = JSON.parse(text)

    for (const fieldName of Object.keys(json)) {
      const field = form.querySelector(`[name=${fieldName}]`)
      if (field) {
        const err = document.createElement('span')
        err.innerHTML = json[fieldName].join('. ')
        err.classList.add('is--error')
        field.parentNode.appendChild(err)
        field.parentNode.classList.add('is--error')
      }
    }
  }

  onServerError() {
    const { resultText } = this.DOM

    resultText.textContent = 'Une erreur est survenue. Merci de réessayer ulterieurement'

    // For output fixed popup
    if (this.params.output === 'popup') this.outputPopup(resultText)
    
    !resultText.classList.contains('is--error') && resultText.classList.add('is--error')
  }

  destroy() {
    const { form, inputs, checkboxContainers } = this.DOM

    form.removeEventListener('submit', this.onSubmit)
    inputs.forEach(input => input.removeEventListener('focusin', this.onFocus))
    inputs.forEach(input => input.removeEventListener('focusout', this.onFocus))

    if (checkboxContainers.length) {
      checkboxContainers.forEach(container => {
        const button = container.querySelector('button')
        button.removeEventListener('click', this.showMoreLabel)
      })
    }
  }
}
