import {domStorage, viewStorage} from "../_globals"
import Form from './form'

export default class Newsletter {
  constructor() {
    this.DOM = {
      newsletter: document.querySelector('#NewsletterForm'),
    }
    if (!this.DOM.newsletter) return

    this.initForm()
  }

  initForm() {
    const { newsletter } = this.DOM

    this.form = new Form(newsletter);
  }

  destroy() {
    const { form } = this

    if (form) form.destroy()
  }
}
