import Swiper, { Navigation, Pagination, Scrollbar } from 'swiper'
Swiper.use([Navigation, Pagination, Scrollbar])

export default class MobileSwiper {
  constructor(container) {
    this.DOM = { container }
    this.DOM.swiperNavigation = this.DOM.container.querySelector('.swiper-navigation')

    this.mobileSwiper
    this.breakpoint = window.matchMedia('(max-width: 768px)')
    this.ww = window.innerWidth

    this.breakpoint.addListener(this.getWindowWidth.bind(this))

    this.getWindowWidth()
  }

  getWindowWidth() {
    this.ww = window.innerWidth

    if (this.breakpoint.matches) {
      return this.enableSwiper()
    } else {
      if (this.mobileSwiper !== undefined) this.mobileSwiper.destroy(true, true)
      return
    }
  }

  enableSwiper() {
    const { container, swiperNavigation } = this.DOM

    this.navigation = false

    if (swiperNavigation) {
      this.navigation = {
        prevEl: container.querySelector('.prev'),
        nextEl: container.querySelector('.next')
      }
    }

    this.swiper = new Swiper(container, {
      speed: 350,
      spaceBetween: 20,
      longSwipesRatio: 0.1,
      slidesPerView: 'auto',
      navigation: this.navigation
    })
  }

  destroy() {
    this.breakpoint.removeListener(this.getWindowWidth.bind(this))

    if (this.swiper) this.swiper.destroy(true, true)
  }
}