import { Renderer } from '@unseenco/taxi';
import { isDEVMODE, viewStorage } from '../_globals'
import VisionClipPath from '../animations/vision-clip-path'
import ActSelf from '../animations/act-self'
import ActPro from '../animations/act-pro'

export default class PageWhyCSP extends Renderer {
  initialLoad() {
    this.onEnter()
  }

  onEnter() {
    this.DOM = {
      visionSection: viewStorage.current.querySelector('.Vision'),
      actSelfSection: viewStorage.current.querySelector('.Act.--self'),
      actProSection: viewStorage.current.querySelector('.Act.--pro')
    }

    this.init()
  }

  init() {
    if (isDEVMODE) console.log(`Init Page : ${this.content.dataset.taxiView}`)
    if (isDEVMODE) console.log(viewStorage.current)

    const { visionSection, actSelfSection, actProSection } = this.DOM

    if (visionSection)  this.clipPath = new VisionClipPath(visionSection)
    if (actSelfSection) this.actSelf = new ActSelf(actSelfSection)
    if (actProSection) this.actPro = new ActPro(actProSection)
  }
 
  onLeaveCompleted() {
    const { clipPath, actSelf, actPro } = this

    if (clipPath) clipPath.destroy()
    if (actSelf) actSelf.destroy()
    if (actPro) actPro.destroy()
  }
}
