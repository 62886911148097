/* global gtag */

/*
Load Plugins / Functions
-------------------------------------------------- */
import { isDEVMODE, globalStorage, viewStorage, domStorage } from './_globals'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
gsap.registerPlugin(ScrollTrigger, ScrollToPlugin)

import { GlobalIntro } from './animations/global-intro'

import viewScroll from './modules/view-scroll.js'
import viewPrllx from './modules/view-prllx.js'
import viewInView from './modules/view-inview.js'
import viewModals from './modules/view-modal'

import ContactAJAX from './modules/contact-ajax'
import TiltCards from './animations/tilt-cards'

const toggleHeaderTheme = () => {
  if (!domStorage.header) return

  if (viewStorage.current.dataset.header === 'dark') {
    domStorage.header.classList.add('--dark')
  } else {
    domStorage.header.classList.remove('--dark')
  }
}

const toggleNewsletterTheme = () => {
  if (!domStorage.newsletter) return

  if (viewStorage.current.dataset.newsletter === 'light') {
    domStorage.newsletter.classList.add('--light')
  } else {
    domStorage.newsletter.classList.remove('--light')
  }
}

let tiltCards = null
let contactAJAX = null

/* --- DOMContentLoaded Function --- */
export const onReady = () => {

  const { body } = domStorage

  viewStorage.viewScroll = new viewScroll()
  viewStorage.viewPrllx = new viewPrllx(body)
  viewStorage.viewInView = new viewInView(body)
  viewStorage.viewModals = new viewModals(body)

  ScrollTrigger.refresh()

  // Intro
  GlobalIntro()

  // Header theme
  toggleHeaderTheme()

  // Newsletter theme
  toggleNewsletterTheme()

  // Add Tilt Cards animations
  tiltCards = new TiltCards(viewStorage.current)

  // Contact AJAX links
  contactAJAX = new ContactAJAX(viewStorage.current)
}


/*
 *  NAVIGATE_IN callback
 *
 * onEnter should only contain event bindings and non-
 * DOM related event measurements. Both view containers
 * are still loaded into the DOM during this callback.
 */
export const onEnter = (to, trigger) => {
  // LoadingClasses
  const { body } = domStorage
  body.classList.remove('--loading')

  // ScrollTop
  const { hasSmoothScroll } = viewStorage
  window.scrollTo(globalStorage.pageScrollTop, globalStorage.pageScrollTop)

  // Update Current View
  viewStorage.current = to.renderer.content

  // Add Page Effects
  setTimeout(() => {
    body.style.overflow = ''
    if (hasSmoothScroll) viewStorage.viewScroll = new viewScroll()
    viewStorage.viewPrllx = new viewPrllx()
    viewStorage.viewInView = new viewInView()
    viewStorage.viewModals = new viewModals()
  }, 150)

  // Header theme
  toggleHeaderTheme()

  // Newsletter theme
  toggleNewsletterTheme()

  // Add Tilt Cards animations
  tiltCards = new TiltCards(viewStorage.current)

  // Contact AJAX links
  contactAJAX = new ContactAJAX(viewStorage.current)
}


/*
 * NAVIGATE_END callback
 *
 * onEnterCompleted should be your primary event callback.
 * The previous view's DOM node has been removed when this
 * event fires.
 */
export const onEnterCompleted = (to, from, trigger) => {
  // Analytics
  if (globalStorage.tarteaucitron && globalStorage.tarteaucitron.job.includes('gtag') && typeof gtag !== 'undefined') {
    gtag('config', globalStorage.tarteaucitron.user.gtagUa, {
      'page_path': targetLocation.pathname,
      'page_title': to.page.title,
      'page_location': targetLocation.href
    })
  }

  if(_paq) {
    _paq.push(['setCustomUrl', location.href]);
    _paq.push(['setDocumentTitle', to.page.title]);
    _paq.push(['trackPageView']);
  }

  /*
  if (typeof _paq !== 'undefined') {
    _paq.push(['setDocumentTitle', to.page.title])
    _paq.push(['setCustomUrl', location.href])
    _paq.push(['trackPageView'])
    isDEVMODE && console.log('Matomo page view sent')
  }*/
}


/*
 * NAVIGATE_OUT callback
 *
 * onLeave is fired when a highway transition has been
 * initiated. This callback is primarily used to unbind
 * events, or modify global settings that might be called
 * in onEnter/onEnterCompleted functions.
 */
export const onLeave = (from, trigger) => {
  // Remove Previous Page Effects
  const { hasInView, hasSticky, hasPrllx, hasModals } = viewStorage
  if (hasInView) viewStorage.viewInView.destroy()
  if (hasSticky) viewStorage.viewSticky.destroy()
  if (hasPrllx) viewStorage.viewPrllx.destroy()
  if (hasModals) viewStorage.viewModals.destroy()

  if (globalStorage.Cursor) globalStorage.Cursor.removeHovers()

  // LoadingClasses
  const { body } = domStorage
  body.classList.add('--loading')
  body.classList.add('--animating')

  // Scroll
  const { hasSmoothScroll } = viewStorage
  body.style.overflow = 'hidden'
  if (hasSmoothScroll) viewStorage.viewScroll.destroy()

  const { menu } = domStorage
  const { menuOpen } = globalStorage

  // Close Menu
  if (menuOpen) globalStorage.closeMobileMenu()

  // Remove Tilt Cards animations
  if (tiltCards) tiltCards.destroy()

  // Remove Contact AJAX links
  if (contactAJAX) contactAJAX.destroy()

  // Remove active links
  const { targetLocation } = globalStorage.taxi
  const navLinks = menu.querySelectorAll('.Nav__link')
  navLinks.forEach((link) => {
    if (link.href === targetLocation.raw) link.classList.add('is--active')
    else link.classList.remove('is--active')
  })

}
