import { globalStorage, isDEVMODE } from '../_globals'
import Plyr from 'plyr'
import gsap from 'gsap'

export default class WknPlyr {

  constructor(container) {

    this.DOM = {
      plyrContainer: container,
      video: container.querySelector('[data-plyr]'),
      btnPlay: container.querySelector('.plyr__play'),
      videoCover: container.querySelector('.plyr__cover')
    }

    if (this.DOM.video.length === 0) return

    this.state = {
      isPlaying: false
    }

    this.init()

  }

  init() {

    if (isDEVMODE) console.log('New WknPlyr')

    this.createPlyr()
    this.addEvents()

  }

  createPlyr() {

    const { video } = this.DOM

    this.plyr = new Plyr(video, {
      controls: ['play', 'progress', 'mute', 'fullscreen'],
      youtube: { noCookie: false, rel: 0, showinfo: 0, iv_load_policy: 3, modestbranding: 1 }
    })

  }

  addEvents() {

    const { plyrContainer, videoCover, btnPlay } = this.DOM

    gsap.set(btnPlay, { xPercent: -50, yPercent: -50 })

    this.onMove = this.onMove.bind(this)
    this.onLeave = this.onLeave.bind(this)
    plyrContainer.addEventListener('mousemove', this.onMove)
    plyrContainer.addEventListener('mouseleave', this.onLeave)

    this.onCoverClick = this.onCoverClick.bind(this)
    videoCover.addEventListener('click', this.onCoverClick)

    this.onReady = this.onReady.bind(this)
    this.plyr.on('ready', this.onReady)

    this.onPlaying = this.onPlaying.bind(this)
    this.plyr.on('playing', this.onPlaying)

    this.onPause = this.onPause.bind(this)
    this.plyr.on('pause', this.onPause)

    this.onSeeking = this.onSeeking.bind(this)
    this.plyr.on('seeking', this.onSeeking)

    this.onEnded = this.onEnded.bind(this)
    this.plyr.on('ended', this.onEnded)
  }

  onMove(e) {
    const { plyrContainer, btnPlay } = this.DOM

    const { top, left, height, width } = plyrContainer.getBoundingClientRect()
    const halfW = width / 2
    const halfH = height / 2
    const mouseX = e.x - left
    const mouseY = e.y - top

    const x = gsap.utils.interpolate(-halfW, halfW, mouseX / width)
    const y = gsap.utils.interpolate(-halfH, halfH, mouseY / height)

    this.tlIn = gsap
      .timeline({ defaults: { ease: 'expo.out', duration: 1 } })
      .to(btnPlay, { x, y, scale: 1.5, overwrite: true }, 0)
      .to(btnPlay.children[0], { scale: 0.5, overwrite: true }, 0)
  }

  onLeave(e) {
    const { btnPlay } = this.DOM

    this.tlOut = gsap
      .timeline({ defaults: { ease: 'expo.inOut', duration: 0.8 } })
      .to(btnPlay, { x: 0, y: 0, scale: 1, overwrite: true }, 0)
      .to(btnPlay.children[0], { scale: 1, overwrite: true }, 0)
  }

  onCoverClick(e) {
    this.plyr.play()

    if (globalStorage.soundActivated) gsap.to(globalStorage.ambientAudio, { volume: 0, playbackRate: 1 })
  }

  onPause(event) {
    if (globalStorage.soundActivated) gsap.to(globalStorage.ambientAudio, { volume: 0.5,  playbackRate: 1 })
  }

  onReady(event) {
    const { currentTarget } = event
    setTimeout(() => currentTarget.classList.add('plyr--hide-controls'), 150)
  }

  onPlaying(event) {
    const { currentTarget } = event
    if (currentTarget.classList.contains('plyr--seeking')) currentTarget.classList.remove('plyr--seeking')
    if (currentTarget.classList.contains('plyr--ended')) currentTarget.classList.remove('plyr--ended')
  }

  onSeeking(event) {
    const { currentTarget } = event
    if (!currentTarget.classList.contains('plyr--seeking')) currentTarget.classList.add('plyr--seeking')
  }

  onEnded(event) {
    const { currentTarget } = event
    currentTarget.classList.add('plyr--hide-controls')
    currentTarget.classList.add('plyr--ended')
  }

  destroy() {
    if (isDEVMODE) console.log('Destroy WknPlyr')

    const { plyr } = this
    const { videoCover } = this.DOM

    if (this.tlIn) this.tlIn.kill()
    if (this.tlOut) this.tlOut.kill()

    videoCover.removeEventListener('click', this.onCoverClick)
    this.plyr.off('ready', this.onReady)
    this.plyr.off('playing', this.onPlaying)
    this.plyr.off('seeking', this.onSeeking)
    this.plyr.off('ended', this.onEnded)
    plyr.destroy()
  }
}
