import { Renderer } from '@unseenco/taxi';
import { isDEVMODE, viewStorage } from '../_globals'
import WknSwiper from '../modules/wkn-swiper'
import MobileSwiper from '../modules/mobile-swiper'
import ScrollTitle from '../modules/scroll-title'
import TiltCards from '../animations/tilt-cards'

export default class PageHowItWorks extends Renderer {
  initialLoad() {
    this.onEnter()
  }

  onEnter() {
    this.DOM = {
      formulasSection: viewStorage.current.querySelector('.Formulas'),
      blockFormulasSwiper: viewStorage.current.querySelector('.Formulas .swiper'),
      blockOfferCartSwiper: viewStorage.current.querySelector('.BlockOffer.--cart .swiper'),
      blockOfferClubSwiper: viewStorage.current.querySelector('.BlockOffer.--club .swiper')
    }

    this.init()
  }

  init() {
    if (isDEVMODE) console.log(`Init Page : ${this.content.dataset.taxiView}`)
    if (isDEVMODE) console.log(viewStorage.current)

    const { formulasSection, blockFormulasSwiper, blockOfferCartSwiper, blockOfferClubSwiper } = this.DOM

    this.tiltCards = new TiltCards(viewStorage.current)
    if (formulasSection) this.setScrollTitle()
    if (blockFormulasSwiper) this.setFormulasSwiper()
    if (blockOfferCartSwiper) this.setOfferCartSwiper() 
    if (blockOfferClubSwiper) this.setOfferClubMobileSwiper()
  }

  setScrollTitle() {
    const { formulasSection } = this.DOM

    this.scrollTitle = new ScrollTitle(formulasSection)
  }

  setFormulasSwiper() {
    const { blockFormulasSwiper } = this.DOM

    this.formulasSwiper = new WknSwiper(blockFormulasSwiper, {
      slidesPerView: 'auto',
      spaceBetween: 0
    })
  }

  setOfferCartSwiper() {
    const { blockOfferCartSwiper } = this.DOM

    this.offerCartSwiper = new WknSwiper(blockOfferCartSwiper, {
      autoHeight: true,
      slidesPerView: 'auto',
      spaceBetween: 0
    })
  }

  setOfferClubMobileSwiper() {
    const { blockOfferClubSwiper } = this.DOM

    this.offerClubMobileSwiper = new MobileSwiper(blockOfferClubSwiper)
  }
 
  onLeaveCompleted() {
    const { tiltCards, formulasSwiper, offerCartSwiper, offerClubMobileSwiper } = this

    if (tiltCards) tiltCards.destroy()
    if (formulasSwiper) formulasSwiper.destroy()
    if (offerCartSwiper) offerCartSwiper.destroy()
    if (offerClubMobileSwiper) offerClubMobileSwiper.destroy()
  }
}
