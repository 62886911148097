import { Renderer } from '@unseenco/taxi';
import { isDEVMODE, viewStorage } from '../_globals'
// import GetFullHeight from '../modules/get-full-height'
import KeywordsUp from '../animations/keywords-up'
import RelatedSolutions from '../modules/related-solutions'
import Testimonial from '../modules/testimonial'

export default class PageHome extends Renderer {
  initialLoad() {
    this.onEnter()
  }

  onEnter() {
    this.DOM = {
      pageHeroVideo: viewStorage.current.querySelector('.PageHero .video__container video'),
      relatedSolutionsSection: viewStorage.current.querySelector('.RelatedSolutions'),
      teamSection: viewStorage.current.querySelector('.Team'),
      testimonialSection: viewStorage.current.querySelector('.Testimonials')
    }

    this.init()
  }

  init() {
    if (isDEVMODE) console.log(`Init Page : ${this.content.dataset.taxiView}`)
    if (isDEVMODE) console.log(viewStorage.current)

    const { pageHeroVideo, relatedSolutionsSection, teamSection, testimonialSection } = this.DOM

    if (pageHeroVideo) {
      pageHeroVideo.autoplay = true
      pageHeroVideo.loop = true
      pageHeroVideo.muted = true
      pageHeroVideo.playsInline = true
    }

    if (relatedSolutionsSection) this.relatedSolutions = new RelatedSolutions(relatedSolutionsSection)
    if (teamSection) this.keywords = new KeywordsUp(teamSection)
    if (testimonialSection) this.testimonial = new Testimonial(testimonialSection)
  }

  onLeaveCompleted() {
    const { plyr, fullHeightHero, relatedSolutions, keywords, testimonial } = this

    if (plyr) plyr.destroy()
    if (fullHeightHero) fullHeightHero.destroy()
    if (relatedSolutions) relatedSolutions.destroy()
    if (keywords) keywords.destroy()
    if (testimonial) testimonial.destroy()
  }
}
