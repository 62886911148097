import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

export default class ActPro {
  constructor(container) {
    this.DOM = { container }
    this.DOM.gallery = this.DOM.container.querySelector('.gallery')

    if (!this.DOM.gallery) return

    this.DOM.visuals = this.DOM.gallery.querySelectorAll('.visual__container')

    this.setVisualsParallax()
  }

  setVisualsParallax() {
    const { container, visuals } = this.DOM

    this.tl = gsap
      .timeline({
        scrollTrigger: {
          trigger: container,
          start: 'top bottom',
          end: 'bottom top',
          scrub: true
        }
      })
      .fromTo(visuals, {
        yPercent: index => {
          switch (index) {
            case 0: return 100
            case 1: return 150
            case 2: return 120
            default: return 0
          }
        },
        scale: index => {
          switch (index) {
            case 0: return 0.8
            case 1: return 0.85
            case 2: return 1
            default: return 1 
          }
        }
      }, {
        yPercent: index => {
          switch (index) {
            case 0: return 0
            case 1: return 20
            case 2: return -30
            default: return -50
          }
        },
        scale: index => {
          switch (index) {
            case 0: return 0.85
            case 1: return 0.7
            case 2: return 1
            default: return 1
          }
        }
      })
  }

  destroy() {
    const { tl } = this

    if (tl) tl.kill()
  }
}