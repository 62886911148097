import { isDEVMODE, globalStorage, domStorage } from '../_globals'
import gsap from 'gsap'
import ScrollToPlugin from 'gsap/ScrollToPlugin'
gsap.registerPlugin(ScrollToPlugin)
import ContactAJAX from './contact-ajax'

export default class Footer {
  constructor() {
    this.DOM = {
      el: domStorage.footer
    }

    this.DOM.backToTop = this.DOM.el.querySelector('#BackToTop')

    this.init()
  }

  init() {
    const { el, backToTop } = this.DOM

    backToTop.addEventListener('click', this.backToTop.bind(this))

    // Contact AJAX
    this.contact = new ContactAJAX(el)
  }

  backToTop(e) {
    e.preventDefault()

    gsap.to(window, { scrollTo: 0, ease: 'expo.inOut', duration: 1.75 })
  }
}
