import { Core } from '@unseenco/taxi'
import { globalStorage } from './_globals'
import { onLeave, onEnter, onEnterCompleted } from './_events'

/* --- Renderers --- */
import Page from './pages/page'
import PageComponents from './pages/components'
import PageHome from './pages/page-home'
import PageHowItWorks from './pages/page-how-it-works'
import PageAbout from './pages/page-about'
import PageOurSolutions from './pages/page-our-solutions'
import PageFAQ from './pages/page-faq'
import PageWhyCSP from './pages/page-why-csp'
import PageContact from './pages/page-contact'
import PagePopp from './pages/page-popp'
import SingleSolution from './pages/single-solution'
import SingleArticle from './pages/single-article'
import ArchiveBlog from './pages/archive-blog'

/* --- Transitions --- */
import PageTransitionDefault from './transitions/page-transition-default'

/* --- Setup Core --- */
globalStorage.taxi = new Core({
  // removeOldContent: false,
  renderers: {
    default: Page,
    pageHome: PageHome,
    pageOurSolutions: PageOurSolutions,
    pageFAQ: PageFAQ,
    pageAbout: PageAbout,
    pageHowItWorks: PageHowItWorks,
    pageWhyCSP: PageWhyCSP,
    pageComponents: PageComponents,
    pageContact: PageContact,
    pagePopp: PagePopp,
    singleSolution: SingleSolution,
    singleArticle: SingleArticle,
    archiveBlog: ArchiveBlog
  },
  transitions: {
    default: PageTransitionDefault
  }
})

/* --- Global Events --- */
globalStorage.taxi.on('NAVIGATE_IN', ({ to, trigger }) => onEnter(to, trigger))
globalStorage.taxi.on('NAVIGATE_OUT', ({ from, trigger }) => onLeave(from, trigger))
globalStorage.taxi.on('NAVIGATE_END', ({ to, from, trigger }) => onEnterCompleted(to, from, trigger))
