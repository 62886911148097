import { isDEVMODE, viewStorage, domStorage } from '../_globals'
import gsap from 'gsap'
import Sharing from './sharing'
import WysiwygWithVideos from './wysiwyg-with-videos'

export default class ArticlesAJAX {
  constructor(container) {
    this.DOM = { container }
    this.DOM.links = this.DOM.container.querySelectorAll('[data-article-ajax]') 
    
    if (!this.DOM.links.length) return

    // Modules
    this.DOM.xhr = null
    // History
    this.currentURL = window.location.href
    this.dynamicURL = null

    this.DOM.links.forEach(link => link.addEventListener('click', this.createPanel.bind(this)))

    this.settings = {
      bodyClassName: '--show-article'
    }
  }

  createPanel(e) {
    const { body } = domStorage
    const { href } = e.currentTarget

    e.preventDefault()

    body.classList.add(this.settings.bodyClassName)
    body.classList.add('--loading')

    fetch(href)
      .then(req => req.text())
      .then(data => {
        body.classList.remove('--loading')

        const parser = new DOMParser()
        const documentParser = parser.parseFromString(data, 'text/html')
        this.DOM.article = documentParser.querySelector('[data-taxi]').children[0]
        this.DOM.articleContent = this.DOM.article.children

        // We want this structure :
        /* 
          <main id="App" data-taxi>
            <div data-taxi-view="...">
              ...
              <div id="xhr">My new div</div>
            </div>
          </main>
        */
        this.DOM.xhr = document.createElement('div')
        this.DOM.xhr.id = 'XHR'
        this.DOM.xhr.innerHTML = `
          <button class="Close" aria-label="Fermer"></button>
          ${this.DOM.article.innerHTML}
        `
        this.DOM.xhr.setAttribute('data-lenis-prevent', '')
        this.DOM.close = this.DOM.xhr.querySelector('.Close')
        this.DOM.sharing = this.DOM.xhr.querySelector('.Sharing')
        this.DOM.description = this.DOM.xhr.querySelector('.wswyg--content')

        body.insertAdjacentElement('beforeend', this.DOM.xhr)

        this.open(href)
      })
      .catch(error => {
        body.classList.remove('--loading')
        body.classList.remove('showModal')

        console.log(`Il y a eu un problème avec l'opération fetch: ${error.message}`)
      })
  }
  
  open(href) {
    const { overlay } = domStorage
    const { xhr, close } = this.DOM

    this.openTl = gsap
      .timeline({
        defaults: { ease: 'power3.inOut', duration: 1 },
        onStart: () => viewStorage.viewScroll.scroll.stop(),
        onComplete: () => {
          gsap.set(xhr, { clearProps: 'xPercent' })
          overlay.addEventListener('click', this.close.bind(this))
          close.addEventListener('click', this.close.bind(this))

          window.history.pushState('', '', href)

          if (this.DOM.sharing) this.sharingModule = new Sharing(this.DOM.sharing)
          if (this.DOM.description) this.wysiwygModule = new WysiwygWithVideos(this.DOM.description)
        }
      })
      .fromTo(xhr, { xPercent: 100 }, { xPercent: 0 }, 0)
  }

  close(e) {
    const { body, overlay } = domStorage
    const { xhr } = this.DOM

    if (e) e.preventDefault()

    this.closeTl = gsap
      .timeline({
        defaults: { ease: 'power3.inOut', duration: 1 },
        onComplete: () => {
          body.classList.remove(this.settings.bodyClassName)

          window.history.pushState('', '', this.currentURL)

          this.DOM.xhr && body.removeChild(xhr)
          this.DOM.xhr = null

          viewStorage.viewScroll.scroll.start()

          if (this.sharingModule) this.sharingModule.destroy()
          if (this.wysiwygModule) this.wysiwygModule.destroy()

          overlay.removeEventListener('click', this.close.bind(this))
        }
      })
      .to(xhr, { xPercent: 100 }, 0)
  }

  destroy() {
    const { links } = this.DOM

    if (links.length) links.forEach(link => link.removeEventListener('click', this.createPanel.bind(this)))
  }
}
