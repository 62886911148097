import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)
import MobileSwiper from './mobile-swiper'

export default class HorizontalScroll {
  constructor(container) {
    this.DOM = { container }
    this.DOM.stickyContainer = this.DOM.container.querySelector('.--sticky')
    this.DOM.wrapper = this.DOM.container.querySelector('.horizontal-scroll') 
    this.DOM.panels = this.DOM.wrapper.querySelectorAll('.panel')
    this.DOM.swipers = this.DOM.wrapper.querySelectorAll('.swiper')

    if (!this.DOM.panels.length) return

    this.swipersModules = []

    // Tweens array
    this.tweens = []
    // MatchMedia variable
    this.mm = gsap.matchMedia()
    // Breakpoint
    this.breakpoint = 768
    // Responsive object
    this.responsiveObj = {
      isDesktop: `(min-width: ${this.breakpoint}px)`,
      isMobile: `(max-width: ${this.breakpoint - 1}px)`
    }

    this.setHorizontalScroll()
    this.setMobileSwipers()
  }

  setHorizontalScroll() {
    const { container, stickyContainer, wrapper, panels } = this.DOM
    const { responsiveObj } = this

    this.mm.add(this.responsiveObj, (context) => {
      const { isDesktop } = context.conditions

      if (isDesktop) {
         // We need to calculate the width of offsets
         let width = 0
         panels.forEach(child => width += child.offsetWidth)

         this.scrollTween = gsap.to(panels, {
          x: -width + window.innerWidth,
          ease: 'none',
          scrollTrigger: {
            trigger: wrapper,
            pin: true,
            scrub: 0.1,
            start: 'top top',
            end: '+=5000',
            // markers: true
          }
        })

        // Let's animate the titles
        panels.forEach((panel, index) => {
          const title = panel.querySelector('h2')
          const cards = panel.querySelector('.cards')

          if (!title) return

          // cards.removeAttribute('data-lenis-prevent', '')

          const tween = gsap.to(title, {
            scale: 0.2,
            xPercent: 200,
            opacity: 0,
            ease: 'none', 
            duration: 1,
            scrollTrigger: {
              trigger: panel,
              containerAnimation: this.scrollTween,
              start: '0% 20%',
              scrub: true
            }
          })

          this.tweens.push(tween)
        })
      } else {
        // Cleaning tweens from desktop
        // + Disable Lenis for horizontal scroll
        gsap.set(panels, { clearProps: 'all' })
        panels.forEach(panel => {
          const title = panel.querySelector('h2')
          const cards = panel.querySelector('.cards')

          if (!title) return

          // cards.setAttribute('data-lenis-prevent', '')

          gsap.set(title, { clearProps: 'all' })
        })
      }
    })
  }

  setMobileSwipers() {
    const { swipers } = this.DOM

    if (!swipers.length) return

    swipers.forEach(swiper => {
      const mobileSwiper = new MobileSwiper(swiper)
      this.swipersModules.push(mobileSwiper)
    })
  }

  destroy() {
    const { scrollTween, tweens, swipersModules } = this

    if (scrollTween) scrollTween.kill()
    if (tweens.length) tweens.forEach(tween => tween.kill())
    if (swipersModules.length) swipersModules.forEach(swiper => swiper.destroy)
  }
}