import MobileSwiper from './mobile-swiper'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

export default class RelatedSolutions {
  constructor(container) {
    this.DOM = { container }
    this.DOM.solutions = this.DOM.container.querySelectorAll('.SolutionCard')

    if (!this.DOM.solutions.length) return

    this.DOM.swiper = this.DOM.container.querySelector('.swiper')

    this.setMobileSwiper()
  }

  setMobileSwiper() {
    const { swiper } = this.DOM

    this.mobileSwiper = new MobileSwiper(swiper)
  }

  destroy() {
    const { mobileSwiper } = this

    if (mobileSwiper) mobileSwiper.destroy()
  }
}