import { isDEVMODE, domStorage } from '../_globals'
import { Transition } from '@unseenco/taxi'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)
import { pageIntro } from '../animations/page-intro'

// We'll create a <div></div> for page transition
let pageTransitionDiv

export default class PageTransitionDefault extends Transition {
  onEnter({ to, trigger, done }) {
    // GSAP Timeline
    gsap.timeline({
      defaults: {  ease: 'power3.inOut', duration: 1 },
      onStart: () => {
        // Updating the ScrollTriggers
        ScrollTrigger.refresh()
      },
      onComplete: () => {
        // Display the new page
        done()
        // Removing body className while animating
        domStorage.body.classList.remove('--animating')
        // Removing the pageTransitionDiv from the DOM
        domStorage.body.removeChild(pageTransitionDiv)
      },
    })
    .to(pageTransitionDiv, { clipPath: 'inset(0% 0% 100% 0%)', overwrite: true }, 0)
    // .fromTo(to, { y: 20, opacity: 0 }, { y: 0, opacity: 1 }, 0)
    .add(pageIntro(0), 0)

  }

  onLeave({ from, trigger, done }) {
    // We need to create a div for page transitions
    // <div class="PageTransition"></div>
    pageTransitionDiv = document.createElement('div')
    pageTransitionDiv.className = 'PageTransition'

    // GSAP Timeline
    gsap
    .timeline({
      delay: 0.2,
      defaults: { ease: 'power3.inOut', duration: 1 },
      onStart: () => {
        // We append the pageTransitionDiv to the body
        document.body.appendChild(pageTransitionDiv)
      },
      onComplete: () => { 
        // Remove the last page
        done()
      }
    })
    .to(from, { y: 20, opacity: 0.5 }, 0)
    .fromTo(pageTransitionDiv, { clipPath: 'inset(100% 0% 0% 0%)' }, { clipPath: 'inset(0% 0% 0% 0%)' }, 0)
  }
}
