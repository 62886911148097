import { viewStorage } from '../_globals'
import Swiper, { Navigation, EffectCards } from 'swiper'
Swiper.use([Navigation, EffectCards])

export default class AccompanimentTabs {
  constructor(container) {
    this.DOM = { container }
    this.DOM.nav = this.DOM.container.querySelector('nav')

    if (!this.DOM.nav) return

    this.DOM.navButtons = this.DOM.nav.querySelectorAll('button')
    this.DOM.tabs = this.DOM.container.querySelectorAll('.AccompanimentTab')

    this.swipers = []

    this.settings = {
      breakpoint: 768,
      activeClassName: '--active'
    }

    this.setNavigationTabs()
    this.setSwipers()
  }

  setNavigationTabs() {
    const { navButtons } = this.DOM

    navButtons.forEach(button => button.addEventListener('click', this.toggleDisplayTab.bind(this)))
  }

  toggleDisplayTab(e) {
    e.preventDefault()

    const { activeClassName } = this.settings
    const { navButtons, tabs } = this.DOM
    const currentButton = e.currentTarget

    // Removing the className for all buttons
    navButtons.forEach(button => button.classList.remove(activeClassName))
    // Add the className only for the current button
    currentButton.classList.add(activeClassName)

    // Check if the button data-type is the same as tab data-type
    tabs.forEach(tab => {
      currentButton.dataset.tab === tab.dataset.tab ? 
        tab.classList.add(activeClassName) : 
        tab.classList.remove(activeClassName)
    })
  }

  setSwipers() {
    const { tabs } = this.DOM

    tabs.forEach(tab => {
      const swiper = tab.querySelector('.swiper')

      if (!swiper) return

      const tabSwiper = new Swiper(swiper, {
        effect: 'cards',
        grabCursor: true,
        navigation: {
          prevEl: tab.querySelector('.prev'),
          nextEl: tab.querySelector('.next')
        }
      })

      this.swipers.push(tabSwiper)
    })
  }

  destroy() {
    const { navButtons } = this.DOM
    const { swipers } = this

    navButtons.forEach(button => button.removeEventListener('click', this.toggleDisplayTab.bind(this)))
    if (swipers.length) swipers.forEach(swiper => swiper.destroy())
  }
}
