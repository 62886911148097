import { Renderer } from '@unseenco/taxi';
import { isDEVMODE, viewStorage } from '../_globals'
import WknPlyr from '../modules/wkn-plyr'
import WknSwiper from '../modules/wkn-swiper'
import Sharing from '../modules/sharing'
import WysiwygWithVideos from '../modules/wysiwyg-with-videos'
import { gsap } from 'gsap'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'

gsap.registerPlugin(ScrollToPlugin)

export default class SingleArticle extends Renderer {

  initialLoad() {
    this.onEnter()
  }

  onEnter() {
    this.DOM = {
      sharing: viewStorage.current.querySelector('.Sharing'),
      navigations: viewStorage.current.querySelectorAll('.Block__navigation'),
      testimonyBlocks: viewStorage.current.querySelectorAll('.Block__testimony'),
      testimoniesBlocks: viewStorage.current.querySelectorAll('.Block__testimonies'),
      newsBlocks: viewStorage.current.querySelectorAll('.Block__news'),
      galleries: viewStorage.current.querySelectorAll('.Block__gallery'),
      description: viewStorage.current.querySelector('.wswyg--content'),
      simulateurBlock: viewStorage.current.querySelector('#rentsimulator')
    }

    this.init()
  }

  init() {
    const { sharing, navigations, description, videos, testimonyBlocks, testimoniesBlocks, newsBlocks, galleries, simulateurBlock } = this.DOM

    if (sharing) this.sharingModule = new Sharing(sharing)
    if (description) this.wysiwygModule = new WysiwygWithVideos(description)
    if (navigations.length > 0 ) this.initNavigations()
    if (testimonyBlocks.length > 0 ) this.initTestimony()
    if (testimoniesBlocks.length > 0 ) this.initTestimonies()
    if (newsBlocks.length > 0 ) this.initNews()
    if (galleries.length > 0 ) this.initGalleries()
    if ( simulateurBlock ) this.initSimulateur()
  }

  initTestimony() {
    const { testimonyBlocks } = this.DOM
    this.testimonyPlyrs = []
    testimonyBlocks.forEach(block => {
      const plyrDOM = block.querySelector('.plyr__container')
      if (plyrDOM) {
        const plyr = new WknPlyr(plyrDOM)
        this.testimonyPlyrs.push(plyr)
      }
    });
  }

  initTestimonies() {
    const { testimoniesBlocks } = this.DOM
    this.testimoniesSwipers = []
    testimoniesBlocks.forEach(block => {
      const swiper = new WknSwiper(block.querySelector('.swiper'), {
        slidesPerView : 'auto',
        spaceBetween : 20,
        navigation: {
          prevEl: block.querySelector('.prev'),
          nextEl: block.querySelector('.next')
        }
      })
      this.testimoniesSwipers.push(swiper)
    });
  }

  initNews() {
    const { newsBlocks } = this.DOM
    this.newsSwipers = []
    newsBlocks.forEach(block => {
      const swiper = new WknSwiper(block.querySelector('.swiper'), {
        spaceBetween : 20,
        navigation: {
          prevEl: block.querySelector('.prev'),
          nextEl: block.querySelector('.next')
        }
      })
      this.newsSwipers.push(swiper)
    });
  }

  initGalleries() {
    const { galleries } = this.DOM
    this.galleries = []
    galleries.forEach(gallery => {
      const swiper = new WknSwiper(gallery.querySelector('.swiper'), {
        slidesPerView : 'auto',
        spaceBetween : 20
      })
      this.galleries.push(swiper)
    });
  }

  initNavigations = (navigations) => {
    let navigationsLinks = []
    navigations.forEach(navigation => {
      const links = navigation.querySelectorAll('[href^="#"]')
      navigationsLinks = [...links]
    })

    navigationsLinks.forEach(link => link.addEventListener('click', (e) => {
      e.preventDefault()
      const targetAnchor = e.currentTarget.getAttribute('href')
      if (targetAnchor !== '#') {
        const elm = document.querySelector(targetAnchor)
        if (elm) gsap.to(window, {duration: 1.8, scrollTo: { y: targetAnchor, offsetY: 40, autoKill: false }, overwrite: 'all', ease: 'expo.inOut' })
      }
    }))
  }

  initSimulateur = () => {
    CreateFrame("CSP","00AA8D","00AA8D");
    iFrameResize({ log: false }, '#rentsimulator')
  }

  onLeaveCompleted() {
    const { sharingModule, wysiwygModule, testimoniesSwipers, newsSwipers, galleries } = this

    if (sharingModule) sharingModule.destroy()
    if (wysiwygModule) wysiwygModule.destroy()
    newsSwipers && newsSwipers.forEach(swiper => swiper.destroy())
    testimoniesSwipers && testimoniesSwipers.forEach(swiper => swiper.destroy())
    galleries && galleries.forEach(gallery => gallery.destroy())
  }
}
