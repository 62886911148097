import { Renderer } from '@unseenco/taxi';
import { isDEVMODE, viewStorage } from '../_globals'
import Accordions from '../modules/accordions'

export default class PageFAQ extends Renderer {
  initialLoad() {
    this.onEnter()
  }

  onEnter() {
    this.DOM = {
      faqAccordions: viewStorage.current.querySelector('.FaqAccordions')
    }

    this.init()

  }

  init() {
    if (isDEVMODE) console.log(`Init Page : ${this.content.dataset.taxiView}`)
    if (isDEVMODE) console.log(viewStorage.current)

    const { faqAccordions } = this.DOM

    if (faqAccordions) this.setAccordions() 
  }

  setAccordions() {
    const { faqAccordions } = this.DOM

    this.accordions = new Accordions(faqAccordions)
  }

  onLeaveCompleted() {
    const { accordions } = this

    if (accordions) accordions.destroy()
  }
}
