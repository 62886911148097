import { Renderer } from '@unseenco/taxi';
import { isDEVMODE, viewStorage } from '../_globals'
import Form from '../modules/form'

export default class ArchiveBlog extends Renderer {
  initialLoad() {
    this.onEnter()
  }

  onEnter() {
    this.DOM = {
      searchForm: viewStorage.current.querySelector('.Form')
    }

    this.init()

  }

  init() {
    if (isDEVMODE) console.log(`Init Page : ${this.content.dataset.taxiView}`)
    if (isDEVMODE) console.log(viewStorage.current)

    const { searchForm } = this.DOM

    if (searchForm) this.searchFormModule = new Form(searchForm)
  }

  onLeaveCompleted() {
    const { searchFormModule } = this

    if (searchFormModule) searchFormModule.destroy()
  }
}
