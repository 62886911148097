import gsap from 'gsap'

export default class KeywordsUp {
  constructor(container) {
    this.DOM = { container }
    this.DOM.keywords = this.DOM.container.querySelectorAll('.kwds span')

    if (!this.DOM.keywords.length) return

    this.setKeywords()
  }

  setKeywords() {
    const { keywords } = this.DOM

    const numberOfWords = keywords.length
    const duration = 1
    const pause = 0.75
    const stagger = duration + pause
    const repeatDelay = stagger * (numberOfWords - 1) + pause

    // gsap.set(keywords, { yPercent: index => index !== 0 && 100, opacity: index => index !== 0 && 0 })
    
    this.tl = gsap
      .timeline({ repeat: -1, defaults: { ease: 'expo.inOut' } })
      .fromTo(keywords, { yPercent: 100, opacity: 0 }, { yPercent: 0, opacity: 1, stagger: { each: stagger, repeat: -1, repeatDelay }, duration })
      .to(keywords, { yPercent: -100, opacity: 0, stagger: { each: stagger, repeat: -1, repeatDelay }, duration  }, stagger)
  }

  destroy() {
    const { tl } = this

    if (tl) tl.kill()
  }
}
