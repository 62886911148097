import { Renderer } from '@unseenco/taxi';
import { isDEVMODE, viewStorage } from '../_globals'
import KeywordsUp from '../animations/keywords-up'
import AboutPresentation from '../modules/about-presentation'
import AccompanimentTabs from '../modules/accompaniment-tabs'

export default class PageAbout extends Renderer {
  initialLoad() {
    this.onEnter()
  }

  onEnter() {
    this.DOM = {
      pageHeroWithKeywords: viewStorage.current.querySelector('.PageHero.--with-keywords'),
      presentation: viewStorage.current.querySelector('.AboutPresentation'),
      accompaniment: viewStorage.current.querySelector('.AboutAccompaniment')
    }

    this.init()

  }

  init() {
    if (isDEVMODE) console.log(`Init Page : ${this.content.dataset.taxiView}`)
    if (isDEVMODE) console.log(viewStorage.current)

    const { pageHeroWithKeywords, presentation, accompaniment } = this.DOM
    
    if (pageHeroWithKeywords) this.keywords = new KeywordsUp(pageHeroWithKeywords)
    if (presentation) this.aboutPresentation = new AboutPresentation(presentation)
    if (accompaniment) this.accompanimentTabs = new AccompanimentTabs(accompaniment)
  }

  onLeaveCompleted() {
    const { keywords, aboutPresentation, accompanimentTabs } = this

    if (keywords) keywords.destroy()
    if (aboutPresentation) aboutPresentation.destroy()
    if (accompanimentTabs) accompanimentTabs.destroy()
  }
}
